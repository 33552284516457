import { useMemo, useState } from "react";
import { DateRange } from "react-day-picker";
import dayjs from "dayjs";
import { useGetOrgServerAnalytics, useGetOrgStorageAnalytics } from "@/services/home";
import { CalendarPopover } from "./calendar-popover";
import { Spinner } from "@/components/spinner";
import { RadioGroup } from "@radix-ui/react-radio-group";
import { RadioGroupItem } from "@/components/ui/radio-group";
import { Label } from "@/components/ui/label";
import { UsersDataTable, usersTableColumns } from "./users-table";
import { Table, TableCell, TableFooter, TableRow } from "@/components/ui/table-complex";
import { formatCurrency, getTimeData } from "@/lib/utils";
import { useGetOrgUserProfile } from "@/services/auth";
import { useActiveOrgId } from "@/hooks/useActiveOrgId";

export const DashboardAnalyticsTab = () => {
  const { orgId } = useActiveOrgId()
  const { data: org, isLoading } = useGetOrgUserProfile(orgId)

  const [range, setRange] = useState<DateRange>({
    from: dayjs().subtract(1, "week").toDate(),
    to: dayjs().toDate(),
  })

  const [activeTab, setActiveTab] = useState<"server" | "storage">("server")

  const rangeData = {
    start_at: range.from?.toISOString() || "",
    end_at: range.to?.toISOString() || ""
  }

  const { data: serverAnalytics, isLoading: isServerAnalyticsLoading } = useGetOrgServerAnalytics({
    ...rangeData
  });

  const { data: storageAnalytics, isLoading: isStorageAnalyticsLoading } = useGetOrgStorageAnalytics({
    ...rangeData
  });

  const activeLoading = activeTab === "server" ? isServerAnalyticsLoading : isStorageAnalyticsLoading
  const activeData = activeTab === "server" ? serverAnalytics : storageAnalytics
  const activeItems = activeData?.items || []

  const totalDuration = useMemo(() => {
    if (activeData?.total_duration_seconds) {
      const { hours, minutes, seconds } = getTimeData(activeData.total_duration_seconds);
      return `${hours}h ${minutes}m ${seconds}s`
    }
  }, [activeData])

  const totalSpent = useMemo(() => {
    if (activeData?.total_spent) {
      const formatted = formatCurrency(activeData.total_spent / 100)
      return `${formatted}`
    }
  }, [activeData])

  return (
    <div>
      <div className="flex items-center mb-4 ml-2">
        <h3 className="text-xl font-semibold mr-12">Users' Usage</h3>

        <RadioGroup className="flex items-center gap-4 mr-auto" defaultValue="server" value={activeTab} onValueChange={val => setActiveTab(val as unknown as "server" | "storage")}>
          <div className="flex items-center space-x-2">
            <RadioGroupItem value="server" id="r1" />
            <Label htmlFor="r1">Server</Label>
          </div>
          <div className="flex items-center space-x-2">
            <RadioGroupItem value="storage" id="r2" />
            <Label htmlFor="r2">Storage</Label>
          </div>
        </RadioGroup>

        <CalendarPopover
          range={range}
          onSelect={setRange}
          shouldDisable={date => {
            return isLoading ? true : dayjs(date).isBefore(dayjs(org?.org.created_at))
          }}
        />
      </div>

      <div className="rounded-md border min-h-[500px]">
        {
          activeLoading ? <Spinner /> : activeData ? (
            <>
              <div className="bg-secondary/50">
                <UsersDataTable
                  columns={usersTableColumns}
                  data={activeItems}
                />
              </div>
              <Table>
                <TableFooter>
                  <TableRow>
                    <TableCell className="py-6 pl-12 font-semibold">Total Account Usage</TableCell>
                    <TableCell className="py-6 font-semibold">{totalDuration}</TableCell>
                    <TableCell className="py-6 font-semibold">{totalSpent}</TableCell>
                  </TableRow>
                </TableFooter>
              </Table>
            </>
          ) : null
        }
      </div>
    </div>
  )
}
