import React, { useMemo, useState } from "react";
import { useGetAuditLogs } from "@/services/audit";
import { Virtuoso } from "react-virtuoso";
import dayjs from "dayjs";
import { CalendarPopover } from "../DashboardHome/calendar-popover";
import { DateRange } from "react-day-picker";
import { ArrowUpDown, Download } from "lucide-react";
import Papa from "papaparse";
import { Button } from "@/components/ui/button";
import {
    Table,
    TableHeader,
    TableHead,
    TableRow,
} from "@/components/ui/table";

const actionMap: { [key: string]: string } = {
    user_invite: "Invited User",
    storage_delete: "Deleted Storage",
    storage_create: "Created Storage",
    server_create: "Created Server",
    server_delete: "Deleted Server",
};

const formatMetadata = (metadata: Record<string, unknown>) => {
    return Object.entries(metadata)
        .map(([key, value]) => `${key}: ${value}`)
        .join(", ");
};

export const DashboardAudit: React.FC = () => {
    const [dateRange, setDateRange] = useState<DateRange>({
        from: dayjs().subtract(1, "month").toDate(),
        to: dayjs().toDate(),
    });

    const [sortBy, setSortBy] = useState("created_at");
    const [sortDirection, setSortDirection] = useState<"asc" | "desc">("desc");

    const startAt = dateRange.from?.toISOString() || "";
    const endAt = dateRange.to?.toISOString() || "";

    const { data, fetchNextPage, hasNextPage, isFetchingNextPage } = useGetAuditLogs(
        startAt,
        endAt,
        sortBy,
        sortDirection
    );

    const allLogs = useMemo(() => data?.pages.flatMap((page) => page.logs) || [], [data]);

    const handleSort = (columnId: string) => {
        if (sortBy === columnId) {
            setSortDirection((prevDirection) => (prevDirection === "asc" ? "desc" : "asc"));
        } else {
            setSortBy(columnId);
            setSortDirection("asc");
        }
    };

    const handleExportCSV = () => {
        if (!allLogs.length) return;

        const formattedData = allLogs.map((log) => ({
            Timestamp: new Date(log.created_at).toLocaleString(),
            User: log.user?.email || "N/A",
            Action: actionMap[log.log_type] || log.log_type,
            Metadata: formatMetadata(log.metadata),
        }));

        const csv = Papa.unparse(formattedData);
        const blob = new Blob([csv], { type: "text/csv;charset=utf-8;" });
        const link = document.createElement("a");
        link.href = URL.createObjectURL(blob);
        link.setAttribute("download", "audit_logs.csv");
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
    };

    return (
        <div>
            <h1 className="text-3xl font-medium mb-4">Audit Log</h1>

            <div className="flex justify-between items-center mb-4">
                <CalendarPopover
                    range={dateRange}
                    onSelect={(range) => {
                        if (range?.from && range?.to) {
                            setDateRange({ from: range.from, to: range.to });
                        }
                    }}
                />
                <Button variant="outline" onClick={handleExportCSV}>
                    <Download className="w-4 h-4 mr-2" />
                    Export CSV
                </Button>
            </div>

            <div className="rounded-md border">
                <Table className="w-full">
                    <TableHeader className="bg-foreground/5">
                        <TableRow className="flex">
                            <TableHead
                                className="flex-1 px-4 py-2 font-bold text-sm cursor-pointer text-foreground/90"
                                onClick={() => handleSort("created_at")}
                            >
                                <div className="flex items-center">
                                    Date
                                    {sortBy === "created_at" && (
                                        <ArrowUpDown
                                            className={`inline-block ml-2 h-4 w-4 ${sortDirection === "asc" ? "rotate-180" : ""}`}
                                        />
                                    )}
                                </div>
                            </TableHead>
                            <TableHead className="flex-1 px-4 py-2 font-bold text-sm text-foreground/90">User</TableHead>
                            <TableHead className="flex-1 px-4 py-2 font-bold text-sm text-foreground/90">Action</TableHead>
                            <TableHead className="flex-1 px-4 py-2 font-bold text-sm text-foreground/90">Metadata</TableHead>
                        </TableRow>
                    </TableHeader>
                </Table>

                <Virtuoso
                    useWindowScroll
                    data={allLogs}
                    endReached={() => hasNextPage && fetchNextPage()}
                    itemContent={(_, log) => (
                        <div className="flex w-full border-b dark:border-gray-700">
                            <div className="flex-1 text-left px-4 py-2 dark:text-gray-300">
                                {new Date(log.created_at).toLocaleString()}
                            </div>
                            <div className="flex-1 text-left px-4 py-2 dark:text-gray-300">
                                {log.user?.email || "N/A"}
                            </div>
                            <div className="flex-1 text-left px-4 py-2 dark:text-gray-300">
                                {actionMap[log.log_type] || log.log_type}
                            </div>
                            <div className="flex-1 text-left px-4 py-2 dark:text-gray-300">
                                {formatMetadata(log.metadata)}
                            </div>
                        </div>
                    )}
                    components={{
                        Footer: () => (
                            <div className="text-center py-4">
                                {isFetchingNextPage ? "Loading..." : "End of list."}
                            </div>
                        ),
                    }}
                />
            </div>
        </div>
    );
};
