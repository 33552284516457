import { useInfiniteQuery, useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import { APIFetch, useTokenAndOrgId } from "../utils";

export interface Notification {
  notification_id: number;
  notification_type: string;
  org_id: number;
  title: string;
  detail: string;
  is_read: boolean;
  is_email_sent: boolean;
  metadata: {
    remaining: number;
    spent: number;
    total: number;
    usage: number;
  };
  created_at: string;
}

const fetchNotifications = async ({
  pageParam = 1,
  orgId,
  token,
  unRead = false,
}: {
  pageParam?: number;
  orgId: number;
  token: string;
  unRead?: boolean;
}): Promise<{
  notifications: Notification[];
  hasNextPage: boolean;
  total: number;
}> => {
  const body = {
    notification_type: "spending_limit",
    org_id: orgId,
    page_number: pageParam,
    page_size: 5,
    ...(unRead && { un_read: true }),
  };

  try {
    const result = await APIFetch.post("listNotifications", {
      token,
      body,
    });

    if (!result || result.status !== "success" || !result.data) {
      throw new Error("Invalid response from server");
    }

    const { notifications, total } = result.data;

    return {
      notifications,
      hasNextPage: notifications.length === 5,
      total,
    };
  } catch (error) {
    console.error("Error fetching notifications:", error);
    return {
      notifications: [],
      hasNextPage: false,
      total: 0,
    };
  }
};

export const useListNotifications = () => {
  const { orgId, token } = useTokenAndOrgId();

  return useInfiniteQuery({
    queryKey: ["list-notifications", orgId, token],
    enabled: !!orgId && !!token,
    queryFn: ({ pageParam = 1 }) =>
      fetchNotifications({ pageParam, orgId, token }),
    initialPageParam: 1,
    getNextPageParam: (lastPage, allPages) => {
      return lastPage.hasNextPage ? allPages.length + 1 : undefined;
    },
  });
};

export const useTotalUnreadCount = () => {
  const { orgId, token } = useTokenAndOrgId();

  return useQuery<number, Error>({
    queryKey: ["total-unread-count", orgId, token],
    queryFn: async () => {
      if (!orgId || !token) return 0;

      const result = await fetchNotifications({
        pageParam: 1,
        orgId,
        token,
        unRead: true,
      });

      return result.total;
    },
    enabled: !!orgId && !!token,
    staleTime: 1000 * 60,
  });
};

interface MarkNotificationResponse {
  success: boolean;
  error?: string;
}

const markNotificationRead = async (
  notificationId: number,
  token: string
): Promise<MarkNotificationResponse> => {
  const body = { notification_id: notificationId };

  const result = await APIFetch.post("markNotificationRead", {
    token,
    body,
  });

  if (!result || result.status !== "success") {
    throw new Error("Failed to mark notification as read");
  }

  return result.data as MarkNotificationResponse;
};

export const useMarkNotificationRead = () => {
  const queryClient = useQueryClient();
  const { token, orgId } = useTokenAndOrgId();

  return useMutation({
    mutationFn: async (notificationId: number) =>
      markNotificationRead(notificationId, token),

    onSuccess: () => {
      queryClient.invalidateQueries({
        queryKey: ["list-notifications", orgId, token],
      });

      queryClient.invalidateQueries({
        queryKey: ["total-unread-count", orgId, token],
      });
    },
  });
};

