import { useState } from "react";
import { UserManagementHeader } from "./header";
import { UsersViewType, UsersSortKeys } from "./types";
import { UserTable } from "./users-table";
import { getOrgUsers, useGetOrgUsers, useCreateOrgInviteBatch, LIST_USERS_PAGE_SIZE } from "@/services/user-management";
import { Spinner } from "@/components/spinner";
import { Virtuoso } from "react-virtuoso";
import { toast } from "react-toastify";
import { useTokenAndOrgId } from "@/services/utils";

export const DashboardUserManagement = () => {
  const [usersView, onUsersViewChange] = useState(UsersViewType.EXISTING);
  const [searchTerm, setSearchTerm] = useState("");
  const [userSort, setUserSort] = useState(UsersSortKeys.CREATED_AT_DESC);

  const { token, orgId } = useTokenAndOrgId();

  const status = usersView === UsersViewType.EXISTING ? "active" : "invited";

  const {
    data,
    isLoading,
    fetchNextPage,
    hasNextPage,
    isFetchingNextPage,
  } = useGetOrgUsers(status, searchTerm, userSort);

  const { mutateAsync: createInviteBatch, isPending: isResending } =
    useCreateOrgInviteBatch({
      onSuccess: () => {
        toast.success("Re-invites sent successfully.");
      },
      onError: () => {
        toast.error("Failed to resend invites. Please try again.");
      },
    });

  const allUsers = data?.allUsers || [];
  const total = data?.total || 0;

  const handleBulkResend = async () => {
    if (isResending) return;

    try {
      // Fetch all pending users dynamically
      const fetchAllPendingUsers = async () => {
        const results = [];
        let currentPage = 1;
        let totalFetched = 0;
        const totalUsers = total; // The total number of pending users from `useGetOrgUsers`

        while (totalFetched < totalUsers) {
          // Fetch the current page of users
          const { users } = await getOrgUsers(
            token,
            orgId.toString(),
            LIST_USERS_PAGE_SIZE,
            currentPage,
            "invited"
          );

          results.push(...users);
          totalFetched += users.length;
          currentPage++;
        }

        return results;
      };

      const allPendingUsers = await fetchAllPendingUsers();

      // Map to invites
      const invites = allPendingUsers.map((user) => ({
        email: user.email,
        role: user.role_name,
      }));

      if (!invites.length) {
        toast.info("No pending users to resend invites to.");
        return;
      }

      // Send batch invites
      await createInviteBatch(invites);

      toast.success("Re-invites sent successfully to all pending users.");
    } catch (error) {
      console.error("Error resending invites:", error);
      toast.error("Failed to resend invites. Please try again.");
    }
  };

  return (
    <div className="flex flex-col gap-5">
      <UserManagementHeader
        usersViewType={usersView}
        onUsersViewChange={onUsersViewChange}
        onSearchChange={setSearchTerm}
        usersViewSortType={userSort}
        onUsersViewSortChange={setUserSort}
      />

      {!isLoading && (
        <div className="flex items-center justify-between ml-4 my-2">
          <div className="text-sm text-foreground/75">
            Showing {allUsers.length} of {total} {status === "active" ? "active" : "invited"} users
          </div>
          {usersView === UsersViewType.PENDING && (
            <button
              className="bg-primary text-white px-4 py-2 rounded-md hover:bg-primary/90"
              onClick={handleBulkResend}
              disabled={isLoading || isResending}
            >
              {isResending ? "Re-sending..." : "Re-Invite All Pending"}
            </button>
          )}
        </div>
      )}

      {isLoading ? (
        <Spinner />
      ) : (
        <Virtuoso
          useWindowScroll
          data={allUsers}
          endReached={() => {
            if (hasNextPage) {
              fetchNextPage();
            }
          }}
          itemContent={(_index, user) => (
            <UserTable
              key={user.user_id}
              usersView={usersView}
              users={[user]}
            />
          )}
          components={{
            Footer: () => (
              <div className="text-center py-8">
                {isFetchingNextPage ? (
                  <Spinner />
                ) : hasNextPage ? null : (
                  <p className="text-sm text-foreground/50">End of list.</p>
                )}
              </div>
            ),
          }}
        />
      )}
    </div>
  );
};
