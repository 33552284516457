import { useActiveOrgProfile } from "@/hooks/useActiveOrgProfile";
import { isRoleAdmin } from "@/services/auth/utils";
import { DashboardHomeAdmin } from "./admin-dashboard";
import { DashboardHomeUser } from "./user-dashboard";
import { Spinner } from "@/components/spinner";

export const DashboardHome = () => {
  const { data, isLoading } = useActiveOrgProfile();
  const isAdmin = isRoleAdmin(data?.role?.role_name);
  if (isLoading) {
    return <Spinner />;
  }

  if (isAdmin) {
    return <DashboardHomeAdmin />;
  }

  return <DashboardHomeUser />;
};