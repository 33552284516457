import {
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuItem,
  DropdownMenuTrigger,
} from "@/components/ui/dropdown-menu";
import {
  SidebarMenu,
  SidebarMenuButton,
  SidebarMenuItem,
} from "@/components/ui/sidebar";
import { useGetUserOrgs } from "@/services/auth";
import { CheckIcon, ChevronsUpDown } from "lucide-react";
import { Badge } from "@/components/ui/badge";
import { Button } from "@/components/ui/button";
import { SidebarHeader } from "@/components/ui/sidebar";
import { useActiveOrgId } from "@/hooks/useActiveOrgId";
import { Link, useNavigate } from "@tanstack/react-router";
import { useActiveOrgProfile } from "@/hooks/useActiveOrgProfile";
import { Avatar, AvatarFallback } from "@/components/ui/avatar";
import { cn } from "@/lib/utils";
import { useTheme } from "@/components/theme-provider";
import { Spinner } from "@/components/spinner";

export const DashboardSidebarHeader = () => {
  const { orgId: activeOrgId } = useActiveOrgId();
  const navigate = useNavigate();
  const { theme } = useTheme();

  return (
    <SidebarHeader className="p-4 flex flex-col border-b border-border">
      <div className="flex items-center my-4">
        <img
          src={theme === "dark" ? "/logo-wide-white.png" : "/logo-wide.png"}
          alt="Inference.ai"
          className="h-8 w-auto dark:py-1 dark:pl-2 mr-auto cursor-pointer"
          // on click navigate to home page
          onClick={() => {
            navigate({
              to: "/$orgId/dashboard/home",
              params: { orgId: activeOrgId?.toString() || "" },
            });
          }}
        />

        <Badge
          variant="outline"
          className="border-primary font-normal text-primary ml-6 cursor-default"
        >
          Beta
        </Badge>
      </div>

      <SidebarMenu>
        <SidebarMenuItem className="min-h-[72px]">
          <SwitchOrg />
        </SidebarMenuItem>
      </SidebarMenu>

      <Button asChild className="w-full my-4">
        <Link
          to="/$orgId/dashboard/servers/new"
          params={{ orgId: activeOrgId?.toString() || "" }}
        >
          Create New Server
        </Link>
      </Button>
    </SidebarHeader>
  );
};

const SwitchOrg = () => {
  const { data: activeOrg, isLoading, isError } = useActiveOrgProfile();
  const { data: userOrgs, isLoading: isUserOrgsLoading } = useGetUserOrgs();

  if (isError) {
    return (
      <div className="text-destructive text-xs">
        Unable to get active org data!
      </div>
    );
  }

  if (isUserOrgsLoading) {
    return <Spinner />;
  }

  const onlyOneOrg = userOrgs?.organizations.length === 1;

  const btn = () => {
    return (
      <SidebarMenuButton
        disabled={isLoading}
        className={cn(
          "rounded-sm min-h-12 h-fit bg-sidebar-foreground/10 w-full",
          !onlyOneOrg
            ? "hover:bg-sidebar-foreground/15"
            : "active:bg-sidebar-foreground/10 hover:bg-sidebar-foreground/10"
        )}
      >
        <OrgAvatar
          avatarUrl={activeOrg?.org.profile_metadata.avatar_preview_url || ""}
          name={activeOrg?.org.name || ""}
        />

        <div className={cn("w-full", !onlyOneOrg ? "max-w-[60%]" : "")}>
          <div className="font-semibold line-clamp-2">
            {activeOrg?.org.name}
          </div>
          <div className="text-xs text-foreground/50 truncate">
            {activeOrg?.role.role_name}
          </div>
        </div>

        {!onlyOneOrg && <ChevronsUpDown className="h-12 ml-2" />}
      </SidebarMenuButton>
    );
  };

  if (onlyOneOrg) {
    return btn();
  }

  return (
    <DropdownMenu>
      <DropdownMenuTrigger asChild>{btn()}</DropdownMenuTrigger>
      <DropdownMenuContent
        side="right"
        align="center"
        className="space-y-1 rounded-sm w-[--radix-popper-anchor-width]"
      >
        <SwitchOrgOptions />
      </DropdownMenuContent>
    </DropdownMenu>
  );
};

const SwitchOrgOptions = () => {
  const { orgId: activeOrgId } = useActiveOrgId();
  const { data: userOrgs, isLoading } = useGetUserOrgs();

  const navigate = useNavigate();

  const onSwitchOrgSelect = (orgId: string, roleName: string) => {
    navigate({
      to:
        roleName === "Admin"
          ? "/$orgId/dashboard/home"
          : "/$orgId/dashboard/servers/new",
      params: { orgId },
    });
  };

  if (isLoading) {
    return <Spinner />;
  }

  return (
    <>
      {userOrgs?.organizations.map((org) => (
        <DropdownMenuItem
          className={cn(
            "flex items-center rounded-sm text-left gap-2 cursor-pointer",
            activeOrgId === org.orgId && "bg-primary/10"
          )}
          key={org.orgId}
          onSelect={() =>
            onSwitchOrgSelect(org.orgId.toString(), org.role.role_name)
          }
        >
          <OrgAvatar
            avatarUrl={org.org.profile_metadata.avatar_preview_url || ""}
            name={org.org.name}
          />

          <div className="flex flex-col w-full max-w-[60%]">
            <span className="font-medium truncate">{org.org.name}</span>
            <span className="text-xs text-foreground/70">
              {org.role.role_name}
            </span>
          </div>

          {org.orgId === activeOrgId && (
            <CheckIcon className="w-4 h-4 text-primary ml-auto" />
          )}
        </DropdownMenuItem>
      ))}
    </>
  );
};

const OrgAvatar = ({
  avatarUrl,
  name,
}: {
  avatarUrl: string;
  name: string;
}) => {
  if (!avatarUrl) {
    return (
      <Avatar className="w-8 h-8">
        <AvatarFallback>{name[0]}</AvatarFallback>
      </Avatar>
    );
  }

  return (
    <img src={avatarUrl} alt={name} className="w-8 min-w-8 h-8 rounded-full" />
  );
};
