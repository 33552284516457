import React, { useState } from "react";
import {
  Dialog,
  DialogContent,
  DialogHeader,
  DialogTitle,
  DialogFooter,
  DialogTrigger,
  DialogDescription,
} from "@/components/ui/dialog";
import { Button } from "@/components/ui/button";
import { Input } from "@/components/ui/input";
import { Avatar, AvatarImage, AvatarFallback } from "@/components/ui/avatar";
import { AlertTriangle, Trash2 } from "lucide-react";
import { Separator } from "@/components/ui/separator";
import { toast } from "react-toastify";
import { useRemoveOrgUser } from "@/services/user-management";
import { useUserStore } from "@/store/UserStore";
import Gravatar from "react-gravatar";
import { UsersViewType } from "./types";

// Extend Button Variants (in shadcn)
const ButtonVariants = {
  outlineDestructive: "border-[#C83A3A] text-[#C83A3A] hover:bg-[#FFE6E6]",
};

// RemoveUserDialog Component
type RemoveUserDialogProps = {
  userId: string;
  userEmail: string;
  usersViewType: UsersViewType;
};

export const RemoveUserDialog = ({
  userId,
  userEmail,
  usersViewType,
}: RemoveUserDialogProps) => {
  const myEmail = useUserStore((state) => state.user?.email);

  const [open, setOpen] = useState(false);
  const [emailInput, setEmailInput] = useState("");

  const { mutate, isPending } = useRemoveOrgUser({
    onSuccess: () => {
      setOpen(false);
      toast.success("User removed successfully");
    },
    onError: () => {
      toast.error("Failed to remove user");
    },
  });

  const onSubmit = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();

    if (emailInput.trim() !== userEmail.toString()) {
      toast.error("User email does not match");
      return;
    }
    mutate(Number(userId));
  };

  const disabled = myEmail === userEmail;
  const buttonText =
    usersViewType === UsersViewType.PENDING ? "Cancel" : "Delete";

  return (
    <Dialog open={open} onOpenChange={setOpen}>
      <DialogTrigger asChild>
        <Button
          variant="outline"
          className={`flex items-center gap-2 ${ButtonVariants.outlineDestructive}`}
          disabled={disabled}
        >
          <Trash2 className="h-4 w-4" />
          {buttonText}
        </Button>
      </DialogTrigger>

      <DialogContent className="max-w-3xl p-10">
        <DialogHeader className="gap-3">
          <DialogTitle className="flex items-center gap-4 font-semibold">
            Are you sure you want to delete this user from the organization?
          </DialogTitle>

          <DialogDescription style={{ color: "#C83A3A" }}>
            This will permanently delete the user with email: "{userEmail}". This
            action is irreversible!
          </DialogDescription>
        </DialogHeader>

        <div className="bg-foreground/10 p-4 rounded-lg mb-4">
          <div className="flex items-center">
            <Gravatar
              email={userEmail[0]}
              className="h-10 w-10 rounded-full mr-4"
            />
            <div>
              <h3 className="font-semibold">--</h3>
              <p className="text-sm text-foreground/50">{userEmail}</p>
            </div>
          </div>
        </div>

        <Separator />

        <form className="space-y-4" onSubmit={onSubmit}>
          <div className="flex flex-col gap-5">
            <p style={{ color: "#C83A3A" }}>
              If you want to continue removing this user, please type the user's
              email below to confirm:
            </p>

            <Input
              value={emailInput}
              onChange={(e) => setEmailInput(e.target.value)}
              required
              className="w-full"
            />
          </div>

          <DialogFooter className="w-full items-center justify-end">
            <Button
              variant="outline"
              className={ButtonVariants.outlineDestructive}
              onClick={() => setOpen(false)}
            >
              Cancel
            </Button>
            <Button type="submit" disabled={isPending}>
              {isPending ? "Removing..." : "Remove"}
            </Button>
          </DialogFooter>
        </form>
      </DialogContent>
    </Dialog>
  );
};

// ComplexDeleteUser Component
export const ComplexDeleteUser = () => {
  const [email, setEmail] = React.useState("");

  const handleRemove = () => {
    // Handle remove logic here
  };

  return (
    <Dialog>
      <DialogTrigger asChild>
        <Button
          variant="outline"
          className={`flex items-center gap-2 ${ButtonVariants.outlineDestructive}`}
        >
          <Trash2 className="h-4 w-4" />
          Delete
        </Button>
      </DialogTrigger>

      <DialogContent>
        <DialogHeader>
          <DialogTitle className="flex items-center font-semibold text-xl">
            <AlertTriangle className="w-6 h-6 mr-2" />
            Warning - This user still has active sessions running.
          </DialogTitle>
        </DialogHeader>
        <div className="text-destructive mb-4">
          <p>
            This user still has active sessions running in either compute,
            storage, or both.
          </p>
          <p>
            Deleting this user's account will terminate all running instances and
            their data will be permanently lost.
          </p>
        </div>
        <div className="bg-foreground/10 p-4 rounded-lg mb-4">
          <div className="flex items-center">
            <Avatar className="h-10 w-10 mr-4">
              <AvatarImage src="/placeholder.svg" />
              <AvatarFallback>JL</AvatarFallback>
            </Avatar>
            <div>
              <h3 className="font-semibold">Jackson Lee</h3>
              <p className="text-sm text-foreground/50">
                jackson.lee@email.com
              </p>
            </div>
          </div>
        </div>
        <div className="mb-4">
          <h4 className="text-destructive font-semibold mb-2">
            The user is currently running:
          </h4>
          <p>Pytorch 2.1 - 10GB VRAM, GPU's, 1 GPU, 30GB Storage @ $0.25 p/h</p>
          <p>42 hours consumed</p>
        </div>

        <Separator />

        <div className="mb-4">
          <p className="text-destructive text-sm mb-4">
            If you want to continue destroying this data and removing the user,
            please enter the email address of the user to confirm:
          </p>
          <Input
            type="email"
            placeholder="username@email.com"
            value={email}
            onChange={(e) => setEmail(e.target.value)}
            className="w-full"
          />
        </div>

        <DialogFooter className="w-full flex items-center justify-between sm:justify-between">
          <Button
            variant="outline"
            className={ButtonVariants.outlineDestructive}
            onClick={() => console.log("Cancelled")}
          >
            Cancel
          </Button>
          <Button
            type="button"
            disabled={email !== "jackson.lee@email.com"}
            onClick={handleRemove}
          >
            Remove
          </Button>
        </DialogFooter>
      </DialogContent>
    </Dialog>
  );
};
