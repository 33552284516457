import React, { useState } from "react";
import { zodResolver } from "@hookform/resolvers/zod";
import { useForm } from "react-hook-form";
import { z } from "zod";
import { Button } from "@/components/ui/button";
import {
    Dialog,
    DialogContent,
    DialogHeader,
    DialogTitle,
    DialogFooter,
    DialogTrigger,
    DialogDescription
} from "@/components/ui/dialog";
import { Textarea } from "@/components/ui/textarea";
import { useCreateSSHKey, useDeleteSSHKey, useGetSSHKeys } from "@/services/sshkeys";
import { Spinner } from "@/components/spinner";
import { Trash2 } from "lucide-react";
import { toast } from "react-toastify";
import { Alert } from "@/components/ui/alert";

const ButtonVariants = {
    outlineDestructive: "border-[#C83A3A] text-[#C83A3A] hover:bg-[#FFE6E6]",
};
const FormSchema = z.object({
    sshKey: z
        .string()
        .refine((val) => /^ssh-(rsa|dss|ed25519|ecdsa) \S+/.test(val.trim()), {
            message: "Invalid SSH key",
        }),
});

interface SetupSSHKeysDialogProps {
    orgId: string | number;
}

export const SetupSSHKeysDialog: React.FC<SetupSSHKeysDialogProps> = ({ orgId }) => {
    const [open, setOpen] = useState(false); // State to control dialog open/close
    const [openDeleteDialog, setOpenDeleteDialog] = useState<string | boolean>(false);

    const form = useForm({
        resolver: zodResolver(FormSchema),
        defaultValues: {
            sshKey: "",
        },
    });

    const { data: sshKeys = [], isLoading: isKeysLoading, error: keysError } = useGetSSHKeys(); // Fetch SSH Keys

    const { mutateAsync: createSSHKey, isPending: isCreating } = useCreateSSHKey({
        onSuccess: () => {
            toast("SSH Key added successfully", { type: "success" });
            form.reset();
        },
        onError: (error) => {
            toast(error.message, { type: "error" });
        },
    });

    const { mutate: deleteSSHKey, isPending: isDeletingSSHKey } = useDeleteSSHKey({
        onSuccess: () => {
            setOpenDeleteDialog(false);
            toast("SSH Key deleted successfully", { type: "success" });
        },
        onError: (error) => {
            toast(error.message, { type: "error" });
        },
    });

    const onSubmit = async (values: z.infer<typeof FormSchema>) => {
        await createSSHKey({ alias: `key-${orgId}`, sshKey: values.sshKey });
    };

    const handleDeleteSSHKey = () => {
        if (typeof openDeleteDialog === "string") deleteSSHKey(openDeleteDialog);
    };

    return (
        <Dialog open={open} onOpenChange={setOpen}>
            {/* Trigger for Opening the Dialog */}
            <DialogTrigger asChild>
                <Button variant="link" className="underline decoration-wavy text-pink-500" onClick={() => setOpen(true)}>
                    Setup SSH Keys
                </Button>
            </DialogTrigger>

            <DialogContent>
                <DialogHeader>
                    <DialogTitle>Add SSH Keys</DialogTitle>
                    <p className="text-sm text-muted-foreground mt-2">
                        Adding an SSH key allows you to securely access compute resources through the terminal using the
                        corresponding private key. You can add a maximum of three separate SSH keys. The system
                        automatically applies these keys during server deployment as an option.
                    </p>
                </DialogHeader>

                {/* Add SSH Key Form */}
                {sshKeys.length < 3 && (
                    <form onSubmit={form.handleSubmit(onSubmit)} className="space-y-4">
                        <div className="flex items-center gap-2">
                            <Textarea
                                placeholder="Paste your public SSH key here"
                                {...form.register("sshKey")}
                                className="flex-1"
                            />
                            <Button type="submit" disabled={isCreating || sshKeys.length >= 3} size="sm">
                                {isCreating ? "Adding..." : "Add"}
                            </Button>
                        </div>
                        {/* Display Form Errors */}
                        <p className="text-red-500 text-sm mt-2">
                            {form.formState.errors.sshKey?.message}
                        </p>
                    </form>
                )}

                <DialogTitle>Your SSH Keys ({sshKeys.length}/3)</DialogTitle>

                {/* List of SSH Keys */}
                <div className="space-y-4 mb-12">
                    {isKeysLoading ? (
                        <Spinner className="mx-0" />
                    ) : sshKeys.length > 0 ? (
                        sshKeys.map((key) => (
                            <div key={key.id} className="flex items-center space-x-4 rounded">
                                <p className="flex-1 rounded-sm p-3 font-mono max-w-[500px] truncate text-sm bg-primary/10 font">
                                    {key.key}
                                </p>
                                <Button
                                    variant="outline"
                                    className={`flex items-center gap-2 ${ButtonVariants.outlineDestructive}`}
                                    onClick={() => setOpenDeleteDialog(key.id)}
                                >
                                    <Trash2 className="h-4 w-4" />
                                    Delete
                                </Button>
                            </div>
                        ))
                    ) : (
                        <div className="text-foreground/50">No SSH Keys Available</div>
                    )}
                </div>

                {/* Display Fetch Errors */}
                {keysError?.message && (
                    <Alert variant="destructive" className="mt-4">
                        {keysError.message}
                    </Alert>
                )}

                {/* Footer with Close Button */}
                <div className="flex justify-end">
                    <Button variant="outline" onClick={() => setOpen(false)}>
                        Close
                    </Button>
                </div>

                {/* Delete Confirmation Dialog */}
                <Dialog open={!!openDeleteDialog} onOpenChange={() => !isDeletingSSHKey && setOpenDeleteDialog(false)}>
                    <DialogContent>
                        <DialogHeader>
                            <DialogTitle>Confirm Delete</DialogTitle>
                            <DialogDescription>
                                Are you sure you want to delete this SSH Key? This action cannot
                                be undone.
                            </DialogDescription>
                        </DialogHeader>
                        <DialogFooter>
                            <Button
                                variant="outline"
                                className={ButtonVariants.outlineDestructive}
                                disabled={isDeletingSSHKey}
                                onClick={handleDeleteSSHKey}
                            >
                                {isDeletingSSHKey ? "Deleting.." : "Confirm"}
                            </Button>
                            <Button
                                variant="secondary"
                                onClick={() => setOpenDeleteDialog(false)}
                            >
                                Cancel
                            </Button>
                        </DialogFooter>
                    </DialogContent>
                </Dialog>
            </DialogContent>
        </Dialog>
    );
};
