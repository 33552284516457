import { DashboardAnalyticsTab } from "./analytics-tab";
import { DashboardHomeHeader } from "./header";
import { DashboardOverviewTab } from "./overview-tab";
import { Tabs, TabsContent } from "@/components/ui/tabs";
import { Button } from "@/components/ui/button";
import { Link } from "@tanstack/react-router";
import { Plus } from "lucide-react";
import { useGetOrgBalanceSummary } from "@/services/home";
import { Spinner } from "@/components/spinner";
import { useActiveOrgId } from "@/hooks/useActiveOrgId";
import { NotificationsTab } from "./notification";

export const DashboardHomeAdmin = () => {
    const { data, isLoading, isError } = useGetOrgBalanceSummary();
    const { orgId: activeOrgId } = useActiveOrgId();

    const renderNoDataFallback = () => (
        <div className="mx-auto flex flex-col items-center gap-16 mt-28">
            <h3 className="text-4xl font-medium">
                There is currently no data to display. Please create a server to get started.
            </h3>
            <img
                src="/assets/image/gpu-pink.png"
                className="w-full max-w-lg"
                alt="No data available"
            />
            <Button asChild size="lg" className="w-fit gap-1">
                <Link to="/$orgId/dashboard/servers/new" params={{ orgId: activeOrgId.toString() }}
                >
                    <Plus className="w-5 h-5" /> Create New Server
                </Link>
            </Button>
        </div>
    );

    if (isLoading) {
        return <Spinner />;
    }

    if (isError || !data || data.spent_total === 0) {
        return renderNoDataFallback();
    }

    return (
        <Tabs defaultValue="overview" className="w-full sm:w-auto">
            <DashboardHomeHeader />

            <TabsContent value="overview">
                <DashboardOverviewTab />
            </TabsContent>
            <TabsContent value="analytics">
                <DashboardAnalyticsTab />
            </TabsContent>
            <TabsContent value="notifications">
                <NotificationsTab />
            </TabsContent>
        </Tabs>
    );
};
