import {
  ColumnDef,
  flexRender,
  getCoreRowModel,
  getSortedRowModel,
  SortingState,
  useReactTable,
} from "@tanstack/react-table"

import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableHeader,
  TableRow,
} from "@/components/ui/table-complex"
import { Button } from "@/components/ui/button";
import { AnalyticsData } from "@/services/home";
import { ArrowUpDown } from "lucide-react";
import Gravatar from "react-gravatar";
import { useState } from "react";
import { ScrollArea } from "@/components/ui/scroll-area";
import { formatCurrency, getTimeData } from "@/lib/utils";

export const usersTableColumns: ColumnDef<AnalyticsData>[] = [
  {
    accessorKey: "user_id",
    header: () => {
      return <h3 className="font-bold ml-12">Users List</h3>
    },
    cell: (cell) => {
      const data = cell.row.original;

      return (
        <div className="flex items-center gap-3">
          {data.profile_metadata?.avatar_preview_url ? (
            <img
              src={data.profile_metadata.avatar_preview_url}
              alt={`${data.profile_metadata?.first_name || ""} ${data.profile_metadata?.last_name || ""}'s avatar`}
              className="h-10 w-10 rounded-full object-cover"
            />
          ) : (
            <Gravatar
              email={data.email}
              className="h-10 w-10 rounded-full"
            />
          )}


          <div>
            <div className="font-medium">{data?.profile_metadata?.first_name || '-'} {data?.profile_metadata?.last_name || '-'}</div>
            < div className="text-sm text-gray-500" > {data.email}</div>
          </div>
        </div>
      )
    }
  },
  {
    accessorKey: "duration_seconds",
    header: ({ column }) => {
      return (
        <Button
          variant="link"
          className="px-0 text-foreground font-bold"
          onClick={() => column.toggleSorting(column.getIsSorted() === "asc")}
        >
          Time Spent
          <ArrowUpDown className="ml-2 h-4 w-4" />
        </Button>
      )
    },
    cell: (cell) => {
      const { hours, minutes, seconds } = getTimeData(cell.getValue() as number);

      return (
        <div className="">
          {hours}h {minutes}m {seconds}s
        </div>
      )
    }
  },
  {
    accessorKey: "spent",
    header: ({ column }) => {
      return (
        <Button
          variant="link"
          className="px-0 text-foreground font-bold"
          onClick={() => column.toggleSorting(column.getIsSorted() === "asc")}
        >
          $ Spent
          <ArrowUpDown className="ml-2 h-4 w-4" />
        </Button>
      )
    },
    cell: ({ row }) => {
      const amount = parseFloat(row.getValue("spent"))
      const formatted = formatCurrency(amount / 100)

      return <div className="font-medium">{formatted}</div>
    },
  }
]

interface DataTableProps<TData, TValue> {
  columns: ColumnDef<TData, TValue>[]
  data: TData[]
}

export function UsersDataTable<TData, TValue>({
  columns,
  data,
}: DataTableProps<TData, TValue>) {
  const [sorting, setSorting] = useState<SortingState>([])

  const table = useReactTable({
    data,
    columns,
    getCoreRowModel: getCoreRowModel(),
    onSortingChange: setSorting,
    getSortedRowModel: getSortedRowModel(),
    state: {
      sorting,
    },
  })

  return (
    <ScrollArea className="h-[600px]">
      <Table>
        <TableHeader>
          {table.getHeaderGroups().map((headerGroup) => (
            <TableRow key={headerGroup.id}>
              {headerGroup.headers.map((header) => {
                return (
                  <TableHead key={header.id} className="text-foreground/90">
                    {header.isPlaceholder
                      ? null
                      : flexRender(
                        header.column.columnDef.header,
                        header.getContext()
                      )}
                  </TableHead>
                )
              })}
            </TableRow>
          ))}
        </TableHeader>
        <TableBody>
          {table.getRowModel().rows?.length ? (
            table.getRowModel().rows.map((row) => (
              <TableRow
                key={row.id}
                data-state={row.getIsSelected() && "selected"}
              >
                {row.getVisibleCells().map((cell) => (
                  <TableCell key={cell.id} className="bg-background">
                    {flexRender(cell.column.columnDef.cell, cell.getContext())}
                  </TableCell>
                ))}
              </TableRow>
            ))
          ) : (
            <TableRow>
              <TableCell colSpan={columns.length} className="h-24 text-center bg-background">
                End of list.
              </TableCell>
            </TableRow>
          )}
        </TableBody>
      </Table>
    </ScrollArea>
  )
}
