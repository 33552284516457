import {
    useGetOrgUserSpendRate,
    useGetOrgUsageGraph,
} from "@/services/home";
import { DateRange } from "react-day-picker";
import {
    Bar,
    BarChart,
    CartesianGrid,
    LabelList,
    XAxis,
    YAxis,
} from "recharts";

import { Card, CardContent } from "@/components/ui/card";
import {
    ChartConfig,
    ChartContainer,
    ChartTooltip,
    ChartTooltipContent,
} from "@/components/ui/chart";
import { useMemo, useState } from "react";
import dayjs from "dayjs";
import { Spinner } from "@/components/spinner";
import { CalendarPopover } from "./calendar-popover";
import { useActiveOrgId } from "@/hooks/useActiveOrgId";
import { useGetOrgUserProfile } from "@/services/auth";
import { formatCurrency } from "@/lib/utils";
import { UserTopStats } from "./top-stats-user";

export const DashboardOverviewTabUser = () => {
    const { orgId } = useActiveOrgId();
    const { data: profile, isLoading: profileLoading } = useGetOrgUserProfile(orgId);

    const userId = profile?.userId;

    if (profileLoading || !userId) {
        return <Spinner />;
    }

    return (
        <>
            <UserTopStats userId={userId} />
            <UserUsageChart userId={userId} />
        </>
    );
};

const UserUsageChart = ({ userId }: { userId: number }) => {
    const [range, setRange] = useState<DateRange>({
        from: dayjs().subtract(1, "week").toDate(),
        to: dayjs().toDate(),
    });

    const { data: spendRate, isLoading: spendRateLoading } = useGetOrgUserSpendRate(userId);
    const { data: usageGraph, isLoading: graphLoading } = useGetOrgUsageGraph(
        {
            interval: "day",
            start_at: range.from?.toISOString() || "",
            end_at: range.to?.toISOString() || "",
        },
        userId
    );

    const chartData = useMemo(() => {
        if (!usageGraph?.items) return [];
        return usageGraph.items.map((item) => ({
            date: dayjs(item.date).format("D MMM"),
            "Total Cost": item.total_cost / 100,
            formatted_total_cost: formatCurrency(item.total_cost / 100),
        }));
    }, [usageGraph]);

    const formattedPeriodSpend = useMemo(() => {
        if (chartData.length === 0) return "$0.00";

        const totalCost = chartData.reduce((acc, item) => acc + item["Total Cost"], 0);

        return formatCurrency(totalCost);
    }, [chartData]);

    const formattedRollingAverageRate = useMemo(() => {
        if (chartData.length === 0) return "$0.00";

        const totalCost = chartData.reduce((acc, item) => acc + item["Total Cost"], 0);
        const averageCost = totalCost / chartData.length;

        return formatCurrency(averageCost);
    }, [chartData]);

    const formattedCurrentSpendRate = useMemo(() => {
        if (!spendRate || typeof spendRate.total_cost !== "number") {
            return "$0.00";
        }
        return formatCurrency(spendRate.total_cost / 100);
    }, [spendRate]);


    const totalDaysDiff = dayjs(range.to).diff(dayjs(range.from), "day");
    const chartConfig = {
        desktop: {
            label: "Desktop",
            color: "hsl(var(--chart-1))",
        },
    } satisfies ChartConfig;

    return (
        <>
            <div className="flex justify-between items-center mb-4">
                <h3 className="text-xl font-semibold">Usage Spent</h3>
                <CalendarPopover
                    range={range}
                    onSelect={setRange}
                    shouldDisable={(date) => dayjs(date).isBefore(dayjs().subtract(1, "year"))}
                />
            </div>

            <Card className="flex flex-col p-6 pl-0 justify-start min-h-[770px]">
                {graphLoading ? (
                    <Spinner />
                ) : (
                    <CardContent className="mt-auto pl-0">
                        <div className="flex flex-col gap-2 pl-8 mb-5">
                            {spendRateLoading ? (
                                <Spinner />
                            ) : (
                                <>
                                    <span>Period Spend</span>
                                    <span className="text-2xl font-bold">
                                        {formattedPeriodSpend}
                                    </span>
                                </>
                            )}
                        </div>
                        <div className="flex flex-row gap-5 pl-8 mb-12">
                            <div className="flex flex-col gap-2">
                                {spendRateLoading ? (
                                    <Spinner />
                                ) : (
                                    <>
                                        <span>Rolling Average:</span>
                                        <span className="text-2xl font-bold">
                                            {formattedRollingAverageRate} / day
                                        </span>
                                    </>
                                )}
                            </div>
                            <div className="flex flex-col gap-2">
                                {spendRateLoading ? (
                                    <Spinner />
                                ) : (
                                    <>
                                        <span>Current Spend Rate:</span>
                                        <span className="text-2xl font-bold">
                                            {formattedCurrentSpendRate} / hr
                                        </span>
                                    </>
                                )}
                            </div>
                        </div>

                        <ChartContainer config={chartConfig} className="max-h-[500px]">
                            <BarChart
                                accessibilityLayer
                                data={chartData}
                                margin={{
                                    top: 20,
                                }}
                            >
                                <CartesianGrid vertical={false} />
                                <YAxis tickLine={false} tickMargin={10} axisLine={false} />
                                <XAxis
                                    dataKey="date"
                                    tickLine={false}
                                    tickMargin={10}
                                    axisLine={false}
                                    tickFormatter={(value) => value.slice(0, 6)}
                                />
                                <ChartTooltip
                                    cursor={false}
                                    content={<ChartTooltipContent className="w-[150px]" />}
                                />
                                <Bar
                                    dataKey="Total Cost"
                                    fill="hsl(var(--chart-1))"
                                    radius={8}
                                >
                                    <LabelList
                                        dataKey="formatted_total_cost"
                                        className="fill-foreground"
                                        content={(data) => {
                                            const { x, y, value } = data;

                                            const isOverflowing = totalDaysDiff > 10;

                                            return (
                                                <g>
                                                    <text
                                                        className="fill-foreground"
                                                        style={{ writingMode: isOverflowing ? "vertical-rl" : "horizontal-tb" }}
                                                        x={Number(x) + (isOverflowing ? 10 : 0)}
                                                        y={Number(y) + (isOverflowing ? 10 : -10)}
                                                    >
                                                        {value}
                                                    </text>
                                                </g>
                                            );
                                        }}
                                    />
                                </Bar>
                            </BarChart>
                        </ChartContainer>
                    </CardContent>
                )}
            </Card>
        </>
    );
};

