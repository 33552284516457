import { STATUS_SUCCESS } from "@/constants/status";
import { APIFetch, useTokenAndOrgId } from "../utils";
import { useQuery } from "@tanstack/react-query";

interface OrgMetricData {
  server_instance_count: number;
  active_user_organization_count: number;
  storage_volume_count: number;
  total_storage_volume_size: number;
}

export const useGetOrgMetrics = (userId?: number) => {
  const { token, orgId } = useTokenAndOrgId();
  return useQuery({
    queryKey: ["org-metric", token, orgId, userId],
    enabled: !!token && !!orgId,
    queryFn: async (): Promise<OrgMetricData> => {
      const body = userId
        ? { org_id: orgId, user_id: userId }
        : { org_id: orgId };

      const result = await APIFetch.post("getOrgMetric", {
        token,
        body,
      });

      if (result.error) {
        throw new Error(`Error: ${result.error}`);
      }

      if (result.status !== STATUS_SUCCESS) {
        throw new Error("Failed to load org metrics, please try again.");
      }

      return result.data;
    },
  });
};

type OrgGraphData = {
  server_graph_data: Array<{
    datetime: string;
    active_servers: number;
  }>;

  ServerUsageData: Array<{
    user_id: number;
    template_id: number;
    total_usage_time_hours: number;
  }>;
};

type OrgUsageGraphData = {
  items: Array<{
    date: string;
    total_cost: number;
  }>;
};

export type GetOrgGraphParams = {
  interval: "hour" | "day" | "week" | "month";
  start_at: string;
  end_at: string;
};

export const useGetOrgGraph = (
  params: GetOrgGraphParams,
  userId?: number,
  enabled: boolean = true
) => {
  const { token, orgId } = useTokenAndOrgId();

  return useQuery({
    queryKey: ["org-graph", token, orgId, userId, params],
    enabled: !!token && !!orgId && enabled,
    queryFn: async (): Promise<OrgGraphData> => {
      const body = userId
        ? { org_id: orgId, user_id: userId, ...params }
        : { org_id: orgId, ...params };

      const result = await APIFetch.post("getOrgGraph", {
        token,
        body,
      });

      if (result.error) {
        throw new Error(`Error: ${result.error}`);
      }

      if (result.status !== STATUS_SUCCESS) {
        throw new Error("Failed to load org graph, please try again.");
      }

      return result.data;
    },
  });
};


type OrgUsersGraphData = {
  items: Array<{
    user: {
      user_id: number;
      email: string;
      profile_metadata: {
        first_name: string;
        last_name: string;
      };
    };
    total_usage: Array<{
      template_id: number;
      hour: number;
    }>;
  }>;
};

export const useGetOrgUsersGraph = (params: GetOrgGraphParams) => {
  const { token, orgId } = useTokenAndOrgId();

  return useQuery({
    queryKey: ["org-users-graph", token, orgId, params],
    enabled: !!token && !!orgId,
    refetchInterval: false,
    queryFn: async (): Promise<OrgUsersGraphData> => {
      const result = await APIFetch.post("getOrgUserGraph", {
        token,
        body: { org_id: orgId, ...params },
      });

      if (result.error) {
        throw new Error(`Error: ${result.error}`);
      }

      if (result.status !== STATUS_SUCCESS) {
        throw new Error(
          "Failed to load org users graph data, please try again."
        );
      }

      const metricData = result?.data;
      return metricData;
    },
  });
};

export type AnalyticsData = {
  duration_seconds: number;
  spent: number;
  user_id: number;
  email: string;
  profile_metadata: {
    first_name: string;
    last_name: string;
    avatar_path?: string;
    avatar_preview_url?: string;
  };
};

type AnalyticsDataReturn = {
  items: Array<AnalyticsData>;
  total_duration_seconds: number;
  total_spent: number;
};

export const useGetOrgServerAnalytics = (
  params: Pick<GetOrgGraphParams, "start_at" | "end_at">
) => {
  const { token, orgId } = useTokenAndOrgId();

  return useQuery({
    queryKey: ["org-server-analytics", token, orgId, params],
    enabled: !!token && !!orgId,
    queryFn: async (): Promise<AnalyticsDataReturn> => {
      const result = await APIFetch.post("getOrgServerAnalytics", {
        token,
        body: { org_id: orgId, ...params },
      });

      if (result.error) {
        throw new Error(`Error: ${result.error}`);
      }

      if (result.status !== STATUS_SUCCESS) {
        throw new Error("Failed to load org server analytics, please try again.");
      }

      return result.data;
    },
  });
};

export const useGetOrgStorageAnalytics = (
  params: Pick<GetOrgGraphParams, "start_at" | "end_at">
) => {
  const { token, orgId } = useTokenAndOrgId();

  return useQuery({
    queryKey: ["org-storage-analytics", token, orgId, params],
    enabled: !!token && !!orgId,
    refetchInterval: false,
    queryFn: async (): Promise<AnalyticsDataReturn> => {
      const result = await APIFetch.post("getOrgStorageAnalytics", {
        token,
        body: { org_id: orgId, ...params },
      });

      if (result.error) {
        throw new Error(`Error: ${result.error}`);
      }

      if (result.status !== STATUS_SUCCESS) {
        throw new Error(
          "Failed to load org stoage analytics, please try again."
        );
      }

      const data = result?.data;
      return data;
    },
  });
};

export type GetOrgBalanceSummaryReturn = {
  total: number;
  spent_total: number;
  usage_percent: number;
};

export const useGetOrgBalanceSummary = () => {
  const { token, orgId } = useTokenAndOrgId();

  return useQuery({
    queryKey: ["org-balance-summary", token, orgId],
    enabled: !!token && !!orgId,
    refetchInterval: false,
    queryFn: async (): Promise<GetOrgBalanceSummaryReturn> => {
      const result = await APIFetch.post("getOrgBalanceSummary", {
        token,
        body: { org_id: orgId },
      });

      if (result.error) {
        throw new Error(`Error: ${result.error}`);
      }

      if (result.status !== STATUS_SUCCESS) {
        throw new Error(
          "Failed to load org balance summary, please try again."
        );
      }

      const data = result?.data;
      return data;
    },
  });
};

export type GetOrgSpendRateReturn = {
  server_cost: number;
  storage_cost: number;
  total_cost: number;
};

export const useGetOrgSpendRate = (userId?: number) => {
  const { token, orgId } = useTokenAndOrgId();

  return useQuery({
    queryKey: ["org-spend-rate", token, orgId, userId],
    enabled: !!token && !!orgId,
    queryFn: async (): Promise<GetOrgSpendRateReturn> => {
      const result = await APIFetch.post("getOrgSpendRate", {
        token,
        body: { org_id: orgId, user_id: userId }, // Include user_id here
      });

      if (result.error) {
        throw new Error(`Error: ${result.error}`);
      }

      if (result.status !== STATUS_SUCCESS) {
        throw new Error("Failed to load org spent rate, please try again.");
      }

      return result?.data;
    },
  });
};

export const useGetOrgUsageGraph = (
  params: GetOrgGraphParams,
  userId?: number,
  enabled: boolean = true
) => {
  const { token, orgId } = useTokenAndOrgId();

  return useQuery({
    queryKey: ["org-usage-graph", token, orgId, userId, params],
    enabled: !!token && !!orgId && enabled,
    queryFn: async (): Promise<OrgUsageGraphData> => {
      const body = userId
        ? { org_id: orgId, user_id: userId, ...params }
        : { org_id: orgId, ...params };

      const result = await APIFetch.post("getOrgUsageGraph", {
        token,
        body,
      });

      if (result.error) {
        throw new Error(`Error: ${result.error}`);
      }

      if (result.status !== STATUS_SUCCESS) {
        throw new Error("Failed to load org usage graph, please try again.");
      }

      return result.data;
    },
  });
};

export type GetOrgUserSpendRateReturn = {
  total_cost: number;
  server_cost: number;
  storage_cost: number;
};


export const useGetOrgUserSpendRate = (userId: number | undefined) => {
  const { token, orgId } = useTokenAndOrgId();

  return useQuery({
    queryKey: ["org-user-spend-rate", token, orgId, userId],
    enabled: !!token && !!orgId && !!userId,
    queryFn: async (): Promise<GetOrgUserSpendRateReturn> => {
      const result = await APIFetch.post("getOrgUserSpendRate", {
        token,
        body: { org_id: orgId, user_id: userId },
      });

      if (result.error) {
        throw new Error(`Error: ${result.error}`);
      }

      if (result.status !== "success") {
        throw new Error("Failed to load user spend rate, please try again.");
      }

      return result.data;
    },
  });
};
