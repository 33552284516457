import { Tabs, TabsContent } from "@/components/ui/tabs";
import { DashboardUserHomeHeader } from "./user-header";
import { DashboardOverviewTabUser } from "./overview-tab-user";
// import { DashboardAnalyticsTabUser } from "./analytics-tab-user";

export const DashboardHomeUser = () => {
    return (
        <Tabs defaultValue="overview" className="w-full sm:w-auto">
            <DashboardUserHomeHeader />
            <TabsContent value="overview">
                <DashboardOverviewTabUser />
            </TabsContent>
            <TabsContent value="analytics">
                {/* <DashboardAnalyticsTabUser /> */}
            </TabsContent>
            <TabsContent value="notifications">
                <div>Notifications</div>
            </TabsContent>
        </Tabs>
    );
};
