import React from "react";
import { useMemo, useState } from "react";
import { Card, CardContent } from "@/components/ui/card";
import { Virtuoso } from "react-virtuoso";
import { Button } from "@/components/ui/button";
import { useListNotifications, useMarkNotificationRead, useTotalUnreadCount } from "@/services/notification";
import type { Notification } from "@/services/notification";

const ButtonVariants = {
    outlineDestructive: "border-[#C83A3A] text-[#C83A3A] hover:bg-[#FFE6E6]",
};

export const NotificationsTab: React.FC = () => {
    const {
        data,
        isLoading,
        isError,
        fetchNextPage,
        hasNextPage,
        isFetchingNextPage,
    } = useListNotifications();

    const markNotificationRead = useMarkNotificationRead();
    const [loadingId, setLoadingId] = useState<number | null>(null);

    const allNotifications = useMemo(
        () => data?.pages.flatMap((page) => page.notifications) || [],
        [data]
    );

    // Updated useTotalUnreadCount to extract data and avoid previous errors
    const { data: totalUnreadCount = 0 } = useTotalUnreadCount();

    if (isLoading) {
        return <div className="text-center py-8">Loading notifications...</div>;
    }

    if (isError) {
        return (
            <div className="text-center text-[#C83A3A] py-8">
                Failed to load notifications.
            </div>
        );
    }

    if (allNotifications.length === 0) {
        return (
            <div className="text-center py-8">
                <p>No notifications available.</p>
            </div>
        );
    }

    const formatTimestamp = (timestamp: string): string => {
        const now = new Date();
        const notificationTime = new Date(timestamp);
        const diffInMs = now.getTime() - notificationTime.getTime();
        const diffInHours = diffInMs / (1000 * 60 * 60);

        if (diffInHours < 1) {
            return "Just now";
        } else {
            return new Intl.DateTimeFormat("en-US", {
                year: "numeric",
                month: "short",
                day: "2-digit",
                hour: "2-digit",
                minute: "2-digit",
                second: "2-digit",
            }).format(notificationTime);
        }
    };

    const formatDetailWithDollarAmounts = (notification: Notification): string => {
        if (!notification.metadata) {
            return "Metadata is not available for this notification.";
        }

        const spent = (notification.metadata.spent || 0) / 100;
        const total = (notification.metadata.total || 0) / 100;
        const remaining = (notification.metadata.remaining || 0) / 100;

        return `
        You have spent $${spent.toLocaleString(undefined, { minimumFractionDigits: 2 })} out of the total $${total.toLocaleString(undefined, { minimumFractionDigits: 2 })} allocated to the account.
        The remaining balance is $${remaining.toLocaleString(undefined, { minimumFractionDigits: 2 })}.
        Please keep an eye on your usage to avoid unexpected costs.
        `;
    };

    const handleMarkAsRead = (notificationId: number) => {
        if (!data) return;

        setLoadingId(notificationId);

        markNotificationRead.mutate(notificationId, {
            onSuccess: () => {
                data.pages.forEach((page) => {
                    page.notifications.forEach((notification: { notification_id: number; is_read: boolean; }) => {
                        if (notification.notification_id === notificationId) {
                            notification.is_read = true;
                        }
                    });
                });

                setLoadingId(null);
            },
            onError: () => {
                setLoadingId(null);
            },
        });
    };

    return (
        <div>
            <div className="mb-4 text-right">
                <span className="text-[#C83A3A] text-sm rounded-full px-3 py-1">
                    Unread: {totalUnreadCount}
                </span>
            </div>

            <Virtuoso<Notification>
                useWindowScroll
                data={allNotifications}
                endReached={() => {
                    if (hasNextPage) {
                        fetchNextPage();
                    }
                }}
                itemContent={(index, notification) => {
                    const isLoading = loadingId === notification.notification_id;

                    return (
                        <Card key={index} className="mb-6 shadow-lg rounded-lg">
                            <CardContent className="p-6">
                                <div className="border-border relative">
                                    <div className="flex items-start justify-between">
                                        <div className="flex gap-3">
                                            {!notification.is_read && (
                                                <div
                                                    className="w-2 h-2 rounded-full bg-red-500 mt-2.5"
                                                />
                                            )}
                                            <h3
                                                className={`font-bold text-lg ${notification.is_read
                                                    ? "text-gray-400"
                                                    : "text-[#C83A3A]"
                                                    }`}
                                            >
                                                {notification.title}
                                            </h3>
                                        </div>
                                        <span className="text-gray-500 text-sm">
                                            {formatTimestamp(notification.created_at)}
                                        </span>
                                    </div>
                                    <p className="text-gray-700 leading-relaxed whitespace-pre-line">
                                        {formatDetailWithDollarAmounts(notification)}
                                    </p>
                                    {!notification.is_read && (
                                        <Button
                                            variant="outline"
                                            className={`absolute bottom-4 right-4 flex items-center gap-2 ${ButtonVariants.outlineDestructive}`}
                                            onClick={() => handleMarkAsRead(notification.notification_id)}
                                            disabled={isLoading}
                                        >
                                            {isLoading ? "Marking..." : "Mark as Read"}
                                        </Button>
                                    )}
                                </div>
                            </CardContent>
                        </Card>
                    );
                }}
                components={{
                    Footer: () => (
                        <div className="text-center py-8">
                            {isFetchingNextPage ? (
                                <div>Loading more notifications...</div>
                            ) : hasNextPage ? null : (
                                <p className="text-sm text-foreground/50">End of list.</p>
                            )}
                        </div>
                    ),
                }}
            />
        </div>
    );
};
