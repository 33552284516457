import React, { useState } from "react";
import {
    Dialog,
    DialogTrigger,
    DialogContent,
    DialogHeader,
    DialogTitle,
    DialogDescription,
    DialogFooter,
} from "@/components/ui/dialog";
import { Button } from "@/components/ui/button";
import { useForm } from "react-hook-form";
import { z } from "zod";
import { zodResolver } from "@hookform/resolvers/zod";
import { Input } from "@/components/ui/input";
import {
    Form,
    FormField,
    FormItem,
    FormLabel,
    FormControl,
    FormDescription,
    FormMessage,
} from "@/components/ui/form";
import { useCreateStorageVolume } from "@/services/storage";
import { toast } from "react-toastify";
import { Alert, AlertDescription, AlertTitle } from "@/components/ui/alert";
import { InfoCircledIcon } from "@radix-ui/react-icons";

const FormSchema = z.object({
    name: z.string().min(2, {
        message: "Name must be at least 2 characters.",
    }),
    size: z.coerce.number().min(1, {
        message: "Size must be at least 1GB.",
    }),
});

interface SetupStorageDialogProps {
    orgId: string | number;
    storageCountLimit?: number;
    storageSizeLimit?: number;
}

export const SetupStorageDialog: React.FC<SetupStorageDialogProps> = ({
    storageCountLimit = 2,
    storageSizeLimit = 5,
}) => {
    const [open, setOpen] = useState(false);

    const form = useForm<z.infer<typeof FormSchema>>({
        resolver: zodResolver(FormSchema),
        defaultValues: {
            name: "",
            size: storageSizeLimit,
        },
    });

    const { mutateAsync, isPending } = useCreateStorageVolume({
        onSuccess: () => {
            setOpen(false);
            toast.success("Storage Volume created successfully");
        },
        onError: (error: Error) => {
            toast.error(`${error}`);
        },
    });

    async function onSubmit(data: z.infer<typeof FormSchema>) {
        await mutateAsync({
            name: data.name.trim(),
            sizeGb: data.size,
        });
        form.reset();
    }

    return (
        <Dialog open={open} onOpenChange={setOpen}>
            <DialogTrigger asChild>
                <Button variant="link" className="underline decoration-wavy text-pink-500">
                    Setup Storage
                </Button>
            </DialogTrigger>

            <DialogContent>
                <DialogHeader>
                    <DialogTitle>Add Storage Volume</DialogTitle>
                    <DialogDescription>
                        Add a persistent storage volume for mounting in server instances.
                    </DialogDescription>
                </DialogHeader>

                <Form {...form}>
                    <form onSubmit={form.handleSubmit(onSubmit)} className="space-y-6">
                        <FormField
                            control={form.control}
                            name="name"
                            render={({ field }) => (
                                <FormItem>
                                    <FormLabel>Name</FormLabel>
                                    <FormControl>
                                        <Input placeholder="Storage volume name" {...field} />
                                    </FormControl>
                                    <FormDescription>
                                        This is the name of the storage volume.
                                    </FormDescription>
                                    <FormMessage />
                                </FormItem>
                            )}
                        />

                        <FormField
                            control={form.control}
                            name="size"
                            render={({ field }) => (
                                <FormItem>
                                    <FormLabel>Size (GB)</FormLabel>
                                    <FormControl>
                                        <Input
                                            type="number"
                                            placeholder="1"
                                            {...field}
                                            min={1}
                                            max={storageSizeLimit}
                                        />
                                    </FormControl>
                                    <FormDescription>
                                        This is the size of the storage volume (GB).
                                    </FormDescription>
                                    <FormMessage />
                                </FormItem>
                            )}
                        />

                        <Alert variant="warning">
                            <InfoCircledIcon className="h-4 w-4" />
                            <AlertTitle>Organization Limits:</AlertTitle>
                            <AlertDescription>
                                {storageCountLimit} active storage volume instances, and a max
                                size of {storageSizeLimit}GB per volume.
                            </AlertDescription>
                        </Alert>

                        <DialogFooter>
                            <Button type="button" variant="ghost" onClick={() => setOpen(false)}>
                                Cancel
                            </Button>
                            <Button disabled={isPending} type="submit">
                                {isPending ? "Creating..." : "Create"}
                            </Button>
                        </DialogFooter>
                    </form>
                </Form>
            </DialogContent>
        </Dialog>
    );
};
