import React, { useMemo } from "react";
import {
  HoverCard,
  HoverCardContent,
  HoverCardTrigger,
} from "@/components/ui/hover-card";
import {
  Breadcrumb,
  BreadcrumbItem,
  BreadcrumbLink,
  BreadcrumbList,
  BreadcrumbSeparator,
} from "@/components/ui/breadcrumb";
import { Button } from "@/components/ui/button";
import { PanelRight, Moon, Sun, BadgeInfo, TriangleAlert } from "lucide-react";
import { useSidebar } from "@/components/ui/sidebar";
import { useTheme } from "@/components/theme-provider";
import { Link, useMatchRoute, useRouterState } from "@tanstack/react-router";
import { Alert, AlertTitle, AlertDescription } from "@/components/ui/alert";
import { useListServerInstances } from "@/services/server";
import { useUserStore } from "@/store/UserStore";
import { useActiveOrgId } from "@/hooks/useActiveOrgId";
import { useGetOrgBalanceSummary } from "@/services/home";
import { cn, formatCurrency } from "@/lib/utils";
import { useActiveOrgProfile } from "@/hooks/useActiveOrgProfile";
import { isRoleAdmin } from "@/services/auth/utils";

export const Header = () => {
  const { theme, setTheme } = useTheme();

  const toggleTheme = () => {
    setTheme(theme === "dark" ? "light" : "dark");
  };

  const { data } = useActiveOrgProfile();
  const isAdmin = isRoleAdmin(data?.role.role_name);

  return (
    <header className="flex flex-col items-left gap-6">
      <div className="flex gap-4 items-center px-6">
        <SidebarButton />
        <LinkBreadCrumbs />

        <div className="ml-auto flex gap-6">
          <BalanceData isAdmin={isAdmin}/>
          <button onClick={toggleTheme}>
            {theme === "dark" ? (
              <Sun className="w-5 h-5" />
            ) : (
              <Moon className="w-5 h-5" />
            )}
          </button>
        </div>
      </div>

      <FirstServerAlert />
    </header>
  );
};

const LinkBreadCrumbs = () => {
  const { orgId } = useActiveOrgId();
  const _breadcrumbs = useRouterState({
    select: (state) => {
      return state.matches
        .map((match) => ({
          title: match.meta?.find((tag) => tag.title)!.title as string,
          path: match.pathname,
        }))
        .filter((crumb) => Boolean(crumb.title));
    },
  });

  const breadcrumbs = useMemo(() => {
    const orgLink = "/" + orgId + "/dashboard/";

    // TODO: current hardcoded, re-write breadcrumbs to support multiple nested routes.
    if (_breadcrumbs[1]?.title === "New Server") {
      return [
        { title: "Home", path: "/" },
        { title: "Servers", path: orgLink + "/servers" },
        { title: "New", path: orgLink + "/servers/new" },
      ];
    }

    return _breadcrumbs;
  }, [_breadcrumbs, orgId]);

  return (
    <Breadcrumb>
      <BreadcrumbList>
        {breadcrumbs.map((crumb, i) => {
          if (!crumb) return null;
          return (
            <React.Fragment key={i}>
              <BreadcrumbItem>
                <BreadcrumbLink asChild>
                  <Link to={crumb.path}>{crumb.title}</Link>
                </BreadcrumbLink>
              </BreadcrumbItem>
              {i === breadcrumbs.length - 1 ? null : <BreadcrumbSeparator />}
            </React.Fragment>
          );
        })}
      </BreadcrumbList>
    </Breadcrumb>
  );
};

const SidebarButton = () => {
  const { isMobile, setOpenMobile } = useSidebar();
  if (!isMobile) return null;

  return (
    <Button
      className="flex items-center gap-2"
      variant="ghost"
      onClick={() => {
        setOpenMobile(true);
      }}
    >
      <PanelRight className="w-4 h-4" />
      Open Sidebar
    </Button>
  );
};

const FirstServerAlert = () => {
  const hasCreatedFirstServer = useUserStore(
    (state) => state.hasCreatedFirstServer
  );
  const { orgId } = useActiveOrgId();

  const matchRoute = useMatchRoute();
  const isStoragePage = !!matchRoute({ to: "/$orgId/dashboard/storage" });
  const isSSHKeysPage = !!matchRoute({ to: "/$orgId/dashboard/sshkeys" });

  const { data, isLoading } = useListServerInstances({
    showAllUsers: false,
    refetchInterval: 10_000,
    staleTime: 5_000,
  });

  const servers = data?.pages.flatMap((page) => page?.instances || []) || [];

  if (hasCreatedFirstServer) return null;

  if (isLoading) return null;
  if (!isStoragePage && !isSSHKeysPage) return null;

  if (servers.length > 0) return null;

  return (
    <Alert className="flex items-center justify-between bg-foreground/5 p-5">
      <BadgeInfo />
      <div className="flex flex-col">
        <AlertTitle className="font-semibold">
          Create your first server
        </AlertTitle>
        <AlertDescription className="text-foreground/60">
          Create your first server to start using the platform.
        </AlertDescription>
      </div>

      <Button variant="outline" asChild>
        <Link
          to="/$orgId/dashboard/servers/new"
          params={{ orgId: orgId.toString() }}
        >
          Create server
        </Link>
      </Button>
    </Alert>
  );
};

interface BalanceDataProps {
  isAdmin: boolean;
}

export const BalanceData = ({ isAdmin }: BalanceDataProps) => {
  const { data, isLoading, isError } = useGetOrgBalanceSummary();

  const dataFormatted = useMemo(() => {
    if (data) {
      return [
        {
          label: "Net Balance",
          data: formatCurrency((data.total - data.spent_total) / 100),
          isNegative: data.total - data.spent_total < 0, // Track negativity
        },
        { label: "Total Balance", data: formatCurrency(data.total / 100) },
        { label: "Total Spent", data: formatCurrency(data.spent_total / 100) },
        { label: "Usage %", data: `${data.usage_percent}%` },
      ];
    }

    return [
      { label: "Net Balance", data: 0, isNegative: false },
      { label: "Total Balance", data: 0 },
      { label: "Total Spent", data: 0 },
      { label: "Usage %", data: 0 },
    ];
  }, [data]);

  const lowBalance = useMemo(() => {
    if (data) {
      return data.total - data.spent_total < 0;
    }

    return false;
  }, [data]);

  if (!isAdmin) return null;
  if (isLoading || isError) return null;

  return (
    <HoverCard>
      <HoverCardTrigger className="text-sm font-semibold cursor-pointer">
        <div className="flex flex-row items-center gap-2">
          {lowBalance && (
            <TriangleAlert className="w-4 h-4" style={{ color: "#C83A3A" }} />
          )}
          <span
            className={cn(
              "font-semibold",
              lowBalance ? "text-[#C83A3A]" : "text-foreground"
            )}
          >
            {dataFormatted[0].label}: {dataFormatted[0].data}
          </span>
        </div>
      </HoverCardTrigger>
      <HoverCardContent className="flex flex-col gap-2">
        {dataFormatted.slice(1).map((d) => (
          <div
            key={d.label}
            className={cn("flex items-center w-full justify-between")}
          >
            <span className="text-sm text-foreground/80">{d.label}:</span>
            <span className="text-sm text-foreground/80">{d.data}</span>
          </div>
        ))}
      </HoverCardContent>
    </HoverCard>
  );
};
