import {
  SidebarFooter,
  SidebarMenu,
  SidebarMenuItem,
} from "@/components/ui/sidebar";
import { useUserStore } from "@/store/UserStore";
import Gravatar from "react-gravatar";
import {
  Dialog,
  DialogContent,
  DialogDescription,
  DialogFooter,
  DialogHeader,
  DialogTitle,
} from "@/components/ui/dialog";
import { Button } from "@/components/ui/button";
import { useState } from "react";
import { useGetOrgUserProfile } from "@/services/auth";
import { useActiveOrgId } from "@/hooks/useActiveOrgId";
import { Navigate, useNavigate } from "@tanstack/react-router";

export const DashboardSidebarFooter = () => {
  const user = useUserStore((state) => state.user);
  const [isLogoutDialogOpen, setIsLogoutDialogOpen] = useState(false);

  const { orgId } = useActiveOrgId();
  const { data: profile } = useGetOrgUserProfile(orgId);

  const firstName = profile?.profile.first_name || "";
  const lastName = profile?.profile.last_name || "";
  const avatarUrl = profile?.profile.avatar_preview_url || "";

  const name = firstName || lastName ? `${firstName} ${lastName}` : "- -";

  if (!user) {
    return <Navigate to="/login" />;
  }

  return (
    <>
      <SidebarFooter className="border-t border-border">
        <SidebarMenu>
          <SidebarMenuItem>
            <div className="flex items-center gap-4 text-sm h-14">
              {avatarUrl ? (
                <img
                  src={avatarUrl}
                  alt={`${name}'s avatar`}
                  className="h-10 w-10 rounded-full"
                />
              ) : (
                <Gravatar email={user.email} className="h-10 w-10 rounded-full" />
              )}
              <div className="w-8/12">
                <div className="font-semibold w-full">{name}</div>
                <div className="text-xs text-foreground/50 truncate">
                  {user.email}
                </div>
              </div>
            </div>

            <Button
              className=""
              size="sm"
              variant="secondary"
              onClick={() => setIsLogoutDialogOpen(true)}
            >
              Logout
            </Button>
          </SidebarMenuItem>
        </SidebarMenu>
      </SidebarFooter>

      <LogoutDialog
        isOpen={isLogoutDialogOpen}
        setIsOpen={setIsLogoutDialogOpen}
      />
    </>
  );
};

type LogoutDialogProps = {
  isOpen: boolean;
  setIsOpen: (value: boolean) => void;
};

const LogoutDialog = ({ isOpen, setIsOpen }: LogoutDialogProps) => {
  const navigate = useNavigate();
  const { clearAuth } = useUserStore();

  const handleLogout = () => {
    clearAuth();
    navigate({ to: "/login" });
  };

  return (
    <Dialog open={isOpen} onOpenChange={setIsOpen}>
      <DialogContent className="sm:max-w-[425px]">
        <DialogHeader>
          <DialogTitle>Confirm Logout</DialogTitle>
          <DialogDescription>
            Are you sure you want to log out?
          </DialogDescription>
        </DialogHeader>
        <DialogFooter>
          <Button variant="outline" onClick={() => setIsOpen(false)}>
            Cancel
          </Button>
          <Button variant="destructive" onClick={handleLogout}>
            Confirm
          </Button>
        </DialogFooter>
      </DialogContent>
    </Dialog>
  );
};
