import { Popover, PopoverContent, PopoverTrigger } from "@/components/ui/popover"
import { Button } from "@/components/ui/button"
import { Calendar } from "@/components/ui/calendar"
import { cn } from "@/lib/utils"
import { DateRange } from "react-day-picker"
import { useState } from "react"
import dayjs from "dayjs"
import { CalendarIcon, ChevronDown } from "lucide-react"


type CalendarPopoverProps = {
  range: DateRange
  onSelect: (range: DateRange) => void
  shouldDisable?: (date: Date) => boolean
}

export const CalendarPopover = ({ range, onSelect, shouldDisable }: CalendarPopoverProps) => {
  const [open, setOpen] = useState(false)
  const [_range, _onSelect] = useState<DateRange | undefined>(range)

  return (
    <Popover
      open={open}
      onOpenChange={(newOpen) => {
        if (newOpen === false) {
          if (!_range || !_range.to) return
        }
        setOpen(newOpen)

        if (newOpen === false && _range && _range.to) {
          onSelect(_range)
        }
      }}
    >
      <PopoverTrigger asChild>
        <Button
          variant={"outline"}
          className={cn(
            "w-[440px] pl-3 justify-start gap-2 text-left border-none py-0 h-fit text-foreground/80 font-normal shadow-none hover:bg-transparent hover:border-border border-2",
          )}
        >
          Report Period:
          <span className="text-primary ml-1">{_range?.from ? dayjs(_range?.from).format("MMM DD YYYY") : "__"} - {_range?.to ? dayjs(_range?.to).format("MMM DD YYYY") : "__"}</span>
          <ChevronDown className="h-4 w-4 mr-7" />
          <CalendarIcon className="h-4 w-4 stroke-primary" />
        </Button>
      </PopoverTrigger>
      <PopoverContent className="w-auto p-0" align="start">
        <Calendar
          mode="range"
          numberOfMonths={2}
          disabled={(date) =>
            date > new Date() || date < new Date("1900-01-01") || (shouldDisable?.(date) || false)
          }
          selected={_range}
          onSelect={newRange => {
            _onSelect(newRange)

            if (newRange && newRange.from && newRange.to) {
              onSelect(newRange)
            }
          }}
          initialFocus
        />
        <div className="flex items-center justify-center py-2">
          {
            !_range ? (
              <p style={{ color: "#C83A3A" }}>Select date range</p>
            ) : !_range.to ? (
              <p style={{ color: "#C83A3A" }}>Select end date</p>
            ) : null
          }
        </div>
      </PopoverContent>
    </Popover>
  )
}
